import MaterialTable from 'material-table';
import MTLocalization from '../utils/MTLocalization';
import React from 'react';
import formatDate from '../utils/formatDate';

const STOCK_AVAILABILITY_CODE_LOOKUP = {
  N: 'Novinka',
  S: 'Skladem',
  D: 'Dotisk',
  R: 'Rozebráno',
  Z: 'Zajišťujeme u dodavatele',
  n: 'Nové vydání',
};

const DistributionList = ({ distributions }) => (
  <MaterialTable
    localization={MTLocalization}
    options={{ toolbar: false, paging: false }}
    style={{ margin: 0, boxShadow: 'none' }}
    columns={[
      { title: 'Klíč distributora', field: 'distributor_key' },
      { title: 'Kód distribuce', field: 'distribution_code' },
      { title: 'Skladem', field: 'stock_availability_count' },
      { title: 'Skladem centrálně', field: 'stock_availability_central_count' },
      { 
        title: 'Skladová dostupnost',
        field: 'stock_availability_code',
        render: (rowData) => STOCK_AVAILABILITY_CODE_LOOKUP[rowData.stock_availability_code] || rowData.stock_availability_code,
      },
      { title: 'Datum budoucího nákupu', field: 'date_of_future_purchase', render: (rowData) => formatDate(rowData.date_of_future_purchase) },
    ]}
    data={distributions}
  />
);

export default DistributionList;
